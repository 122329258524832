import WorkspacesIcon from '@mui/icons-material/Workspaces';
// import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
// import GroupsIcon from '@mui/icons-material/Groups';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DeskIcon from '@mui/icons-material/Desk';
import DeckIcon from '@mui/icons-material/Deck';
import DnsIcon from '@mui/icons-material/Dns';

import LTypeWorkstationImage from "./../media/thumbnails/L shape workstation.jpg"
import LinearWorkstationImage from "./../media/thumbnails/Linear Workstation.jpg"

import MDCabinImage from "./../media/thumbnails/MD_Cabin2.jpg"
import ManagerCabinImage from "./../media/thumbnails/Manager Cabin.jpg"
import SmallCabinImage from "./../media/thumbnails/Small Cabin.jpg"

import BoardRoomImage from "./../media/thumbnails/Board Room.png"
import ConferenceRoomImage from "./../media/thumbnails/Conference Room.png"
import DiscussionRoomImage from "./../media/thumbnails/Discussion Room.png"
import HRRoomImage from "./../media/thumbnails/HR Room.png"
import InterviewRoomImage from "./../media/thumbnails/Interview Room.png"
import MeetingRoomImage from "./../media/thumbnails/Meeting Room 6pax.jpg"
import MeetingRoomLargeImage from "./../media/thumbnails/Meeting Room 12 pax.png"

import FitnessZoneImage from "./../media/thumbnails/fitnesszone.png"
import FinanceRoomImage from "./../media/thumbnails/Finance Room.png"

import PantryImage from "./../media/thumbnails/Pantry.png"
import PhoneBoothImage from "./../media/thumbnails/Phone Booth.png"
import ReceptionImage from "./../media/thumbnails/Reception.jpg"
import SalesTeamImage from "./../media/thumbnails/Sales Team.png"
import UPSRoomImage from "./../media/thumbnails/UPS Room.jpg"
import BMSRoomImage from "./../media/thumbnails/BMS Room.jpg"
import ServerRoomImage from "./../media/thumbnails/Server Room.png"


export const toSize = (totalArea) => {
  const size = (totalArea <= 1000
    ?
    "xs"
    :
    (totalArea <= 2000
      ?
      "sm"
      :
      (totalArea <= 3000
        ?
        "md"
        :
        (totalArea <= 4000
          ?
          "lg"
          :
          "xl"
        )
      )
    )
  )
  return size
}

const calculateArea = (data, size, totalArea) => {
  return data.reduce((acc, cur) => {
    console.log(cur)
    // if (!cur.show) 
    //   acc = acc + (totalArea * cur.area[size])
    // else if (cur.show && cur.units > 0)
    if (cur.show && cur.units > 0)
      acc = acc + (cur.units * cur.apu[size])
      if (cur.extra)
        acc = acc + cur.extra
    console.log(acc)
    return acc
  }, 0)
}
export const unitCalculator = (totalArea, data) => {

  const size = toSize(totalArea)
  const firstMapping = {
    xs: {
      mdc: 1,
      sc: 1,
      lp: 1,
      rc: 1,
      srv: 1
    },
    sm: {
      mdc: 1,
      mgc: 2,
      sc: 1,
      lp: 1,
      fo: 1,
      rc: 1,
      srv: 1
    },
    md: {
      mdc: 1,
      mgc: 2,
      sc: 2,
      lp: 1,
      fo: 1,
      mr: 1,
      hr: 1,
      rc: 1,
      srv: 1
    },
    lg: {
      mdc: 1,
      mgc: 4,
      sc: 2,
      lp: 1,
      fo: 1,
      mr: 1,
      lmr: 1,
      hr: 1,
      dr: 1,
      cr: 1,
      st: 1,
      rc: 1,
      srv: 1
    },
    xl: {
      mdc: 1,
      mgc: 4,
      lp: 1,
      fo: 1,
      cr: 1,
      mr: 1,
      lmr: 1,
      hr: 1,
      dr: 1,
      st: 2,
      rc: 1,
      srv: 1
    },
  }

  console.log(size)

  const firstPassData = data.map(d => ({ ...d, units: firstMapping[size][d.id] ?? 0 }))
  let reachedArea = calculateArea(firstPassData, size, totalArea)
  let secondPassData = [...firstPassData]
  console.log(reachedArea)
  console.log(secondPassData)
  while (reachedArea > totalArea) {
    const tempData = secondPassData.filter(d => d.show && d.units > 0).sort(
      (a, b) => (a.p - b.p) || (a.units - b.units)
    )
    const low = tempData[0]
    const index = secondPassData.findIndex(d => d.id === low.id)
    secondPassData[index] = { ...low, units: low.units - 1 }
    reachedArea = calculateArea(secondPassData, size, totalArea)
    console.log(reachedArea)
    console.log(secondPassData)
  }

  console.log(secondPassData)

  reachedArea = calculateArea(secondPassData, size, totalArea)
  console.log(totalArea, reachedArea)
  

  const lwIndex = secondPassData.findIndex(o => o.id === "lw")
  const ltwIndex = secondPassData.findIndex(o => o.id === "ltw")

  const lw = secondPassData[lwIndex]
  const ltw = secondPassData[ltwIndex]

  const circulationIndex = secondPassData.findIndex(o => o.id === "circulation")
  const circulation = secondPassData[circulationIndex]

  const pendingArea = totalArea - reachedArea

  const lwUnits = Math.floor((pendingArea / 2) / ((lw.apu[size] * (1 + circulation.area[size]))))
  const ltwUnits = Math.floor((pendingArea / 2) / ((ltw.apu[size] * (1 + circulation.area[size]))))

  // const lwExtraIdea = (lw.apu[size] * lwUnits)
  // const ltwExtraIdea = (ltw.apu[size] * ltwUnits)

  // const lwExtra = (lwExtraIdea * padding) / (lwExtraIdea + ltwExtraIdea)
  // const ltwExtra = (ltwExtraIdea * padding) / (lwExtraIdea + ltwExtraIdea)

  secondPassData[lwIndex] = { ...lw, units: lwUnits }
  secondPassData[ltwIndex] = { ...ltw, units: ltwUnits }

  return secondPassData

}

export const treemapData = [
  { id: "lw", name: "Linear Workstations", image: LinearWorkstationImage, tags: [], apu: { xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }, category: "Open Workspaces", p: 1, color: "#7f9cf5", show: true },
  { id: "ltw", name: "L-Type Workstations", image: LTypeWorkstationImage, tags: [], apu: { xs: 25, sm: 25, md: 25, lg: 25, xl: 25 }, category: "Open Workspaces", p: 1, color: "#667eea", show: true },

  { id: "mdc", name: "MD Cabin", tags: [], image: MDCabinImage, apu: { xs: 120, sm: 140, md: 160, lg: 180, xl: 200 }, category: "Cabins", p: 3, color: "#f56565", show: true },
  { id: "mgc", name: "Manager Cabin", tags: [], image: ManagerCabinImage, apu: { xs: 80, sm: 80, md: 80, lg: 80, xl: 80 }, category: "Cabins", p: 2, color: "#fc8181", show: true },
  { id: "sc", name: "Small Cabin", tags:[], image: SmallCabinImage, apu: { xs: 80, sm: 80, md: 80, lg: 80, xl: 80 }, category: "Cabins", color: "#fabbbb", show: true },

  { id: "dr", name: "Discussion Room", tags: ["18 pax"], image: DiscussionRoomImage, apu: { xs: 380, sm: 380, md: 380, lg: 380, xl: 380 }, category: "Meeting Rooms", p: 1, color: "#68d391", show: true },
  { id: "irs", name: "Interview Room", tags: ["4 pax"], image: InterviewRoomImage, apu: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }, category: "Meeting Rooms", p: 1, color: "#48bb78", show: true },
  { id: "cr", name: "Conference Room", tags: ["20 pax"], image: ConferenceRoomImage, apu: { xs: 250, sm: 250, md: 250, lg: 250, xl: 250 }, category: "Meeting Rooms", p: 2, color: "#ecc94b", show: true },
  { id: "br", name: "Board Room", tags: ["16 pax"], image: BoardRoomImage, apu: { xs: 325, sm: 325, md: 325, lg: 325, xl: 325 }, category: "Meeting Rooms", p: 1, color: "#f6e05e", show: true },
  { id: "mr", name: "Meeting Room", tags: ["6 pax"], image: MeetingRoomImage, apu: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }, category: "Meeting Rooms", p: 1, color: "#faf089", show: true },
  { id: "lmr", name: "Meeting Room", tags: ["Large", "12 pax"], image: MeetingRoomLargeImage, apu: { xs: 300, sm: 300, md: 300, lg: 300, xl: 300 }, category: "Meeting Rooms", p: 1, color: "#faf089", show: true },
  { id: "hr", name: "HR Room", tags: [], image: HRRoomImage, apu: { xs: 80, sm: 80, md: 80, lg: 80, xl: 80 }, category: "Meeting Rooms", p: 1, color: "#9ae6b4", show: true },
  { id: "fo", name: "Finance Office", tags: ["6 pax"], image: FinanceRoomImage, apu: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }, category: "Meeting Rooms", p: 1, color: "#63b3ed", show: true },

  { id: "rc", name: "Reception", tags: [], image: ReceptionImage, apu: { xs: 80, sm: 120, md: 160, lg: 160, xl: 160 }, category: "Public Spaces", p: 1, color: "#90cdf4", show: true },
  { id: "lp", name: "Lounge / Pantry", tags: [], image: PantryImage, apu: { xs: 50, sm: 80, md: 100, lg: 150, xl: 200 }, category: "Public Spaces", p: 2, color: "#bee3f8", show: true },
  { id: "pb", name: "Phone Booth", tags: [], image: PhoneBoothImage, apu: { xs: 25, sm: 25, md: 25, lg: 25, xl: 25 }, category: "Public Spaces", p: 1, color: "#b794f4", show: true },
  { id: "st", name: "Sales Team", tags: ["4 pax"], image: SalesTeamImage, apu: { xs: 80, sm: 80, md: 80, lg: 80, xl: 80 }, category: "Public Spaces", p: 2, color: "#9f7aea", show: true },
  { id: "fz", name: "Fitness Zone", tags: [], image: FitnessZoneImage, apu: { xs: 250, sm: 250, md: 250, lg: 250, xl: 250 }, category: "Public Spaces", p: 1, color: "#d6bcfa", show: true },

  { id: "ups", name: "UPS Room", tags: [], image: UPSRoomImage, apu: { xs: 90, sm: 90, md: 90, lg: 90, xl: 90 }, category: "Support Spaces", p: 1, color: "#38b2ac", show: true },
  { id: "bms", name: "BMS Room", tags: [], image: BMSRoomImage, apu: { xs: 90, sm: 90, md: 90, lg: 90, xl: 90 }, category: "Support Spaces", p: 1, color: "#4fd1c5", show: true },
  { id: "srv", name: "Server Room", tags:[], image: ServerRoomImage, apu: { xs: 20, sm: 40, md: 60, lg: 80, xl: 100 }, category: "Support Spaces", p: 1, color: "#5ff1e6", show: true},

  { id: "circulation", name: "Circulation Area", tags:[], area: { xs: 0.15, sm: 0.15, md: 0.15, lg: 0.20, xl: 0.20 }, image: null, category: null, p: 0, color: "#cccccc", show: false},
  
// { id: "lw", name: "Carpet/Corridor", apu: { xs: 300, sm: 300, md: 300, lg: 300, xl: 300 }, category: "Circulation and Support Spaces"},
  // { id: "lw", name: "UPS/Battery Room", apu: { xs: 60, sm: 60, md: 60, lg: 60, xl: 60 }, category: "Circulation and Support Spaces"},
  // { id: "lw", name: "Fire Suspension Room", apu: { xs: 60, sm: 60, md: 60, lg: 60, xl: 60 }, category: "Circulation and Support Spaces"},
  // { id: "lw", name: "Reprographic Area", apu: { xs: 25, sm: 25, md: 25, lg: 25, xl: 25 }, category: "Circulation and Support Spaces"},
  // { id: "lw", name: "Storage Room", apu: { xs: 90, sm: 90, md: 90, lg: 90, xl: 90 }, category: "Circulation and Support Spaces"},
  // { id: "lw", name: "Compactor Room", apu: { xs: 140, sm: 140, md: 140, lg: 140, xl: 140 }, category: "Circulation and Support Spaces"},

]

export const categories = [
  { id: "lw", name: "Open Workspaces", icon: WorkspacesIcon },
  { id: "lw", name: "Cabins", icon: DeskIcon },
  { id: "lw", name: "Meeting Rooms", icon: Diversity3Icon },
  { id: "lw", name: "Public Spaces", icon: DeckIcon },
  { id: "lw", name: "Circulation and Support Spaces", icon: DnsIcon }
]