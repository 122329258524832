import React, { useCallback, useEffect } from "react"
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material"
import { useState } from "react"
import classNames from "classnames"
import getFurnitureImages, { CO, question, choice_order } from "../../utils/FurnitureImage"
import loveImage from "../../media/cursors/love.webp"
import NextButton from "../../components/nextButton"
import { preloadImage } from "../../utils/useImagePreloader"
import Loader from "../../components/loader"
import { AnimatePresence, motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux";
import {
  setFurnitureInput
} from "../../utils/formSlice";

import { RootState } from "../../utils/store";


const ResultOption = ({ name, images, percentage, desc, maxLength }: { name: string, images: Array<string>, percentage: number, desc: string, maxLength: number }) => {
  const extra = new Array(maxLength - images.length).fill("x")
  const cols = [6].includes(maxLength) ? 3 : 4
  // console.log(extra)  
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: .5 }}
        className="basis-1/2 flex flex-col border border-slate-200 max-w-md xxl:max-w-lg">
        <div className={classNames("grid gap-2 p-2 sm:p-4 lg:p-8", (cols === 3 ? "grid-cols-3" : "grid-cols-4 md:grid-cols-5 lg:grid-cols-4"))}>
          {images.map((img, i) => <div key={`${name}_image_${i}`}><img src={img} className="aspect-square object-cover" alt="" /></div>)}
          {extra.map((e, i) => <div key={`${name}_extra_${i}`} className="hidden sm:block aspect-square"></div>)}
        </div>
        <div className="flex flex-col p-2 sm:p-4 sm:py-2 lg:p-8 lg:pt-0">
          <div className="relative w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700 sm:my-2 md:my-3 lg:my-5">
            <AnimatePresence>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${percentage}%` }}
                transition={{ duration: .5 }}
                className={classNames(
                  "bg-blue-600 h-full rounded-full flex justify-end"
                )} style={{ "width": `${percentage}%` }}>
                <span className="text-xs text-white pr-1 font-semibold">{percentage}%</span>
              </motion.div>
            </AnimatePresence>
          </div>
          <h3 className="font-bold text-xl sm:my-2 md:my-3 lg:my-5">{name}</h3>
          <p className="text-xs md:text-sm">{desc}</p>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default function FurnitureQuiz({ goToPrevious, goToNext }: { goToPrevious?: () => void, goToNext: (data: any, callback?: () => void) => void }) {

  const [questions, setQuestions] = useState<Array<any>>([])
  const [currentQuestion, setCurrentQuestion] = useState<question | null>(null)
  const [upcomingQuestion, setUpcomingQuestion] = useState<question | null>(null)
  const dispatch = useDispatch()
  const filledData = useSelector((state: RootState) => state.form.furnitureInput)
  const setFilledData = useCallback((x: Array<any>) => {dispatch(setFurnitureInput(x))}, [dispatch])

  const imagesPreloaded = useSelector((state: RootState) => state.form.furnitureImagesPreloaded)
  const qlist = useSelector((state: RootState) => state.form.furntitureQuizQuestions)

  useEffect(() => {
    setQuestions(qlist)
    setCurrentQuestion(qlist[0])
    setUpcomingQuestion(qlist[1])
    setFilledData(new Array(qlist.length).fill(null))
  }, [qlist, setQuestions, setCurrentQuestion, setFilledData])

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const [lastQuestion, setLastQuestion] = useState(false)

  const [showSummary, setShowSummary] = useState(false)

  const [tapHeart, setTapHeart] = useState<any>(null)

  const nextQuestion = (choice: any) => {

    const x = [...filledData]
    x[currentQuestionIndex] = choice
    setFilledData(x)

    if (lastQuestion) setShowSummary(true)
    else {
      const newIndex = currentQuestionIndex + 1
      setCurrentQuestionIndex(newIndex)
      setCurrentQuestion(questions[newIndex])
      setUpcomingQuestion((newIndex === 9 ? null : questions[newIndex + 1]))
      setLastQuestion(newIndex === 9)
    }
  }

  const previousQuestion = () => {
    const newIndex = currentQuestionIndex - 1
    setUpcomingQuestion(questions[currentQuestionIndex])
    setCurrentQuestionIndex(newIndex)
    setCurrentQuestion(questions[newIndex])
    setLastQuestion(newIndex === 9)
  }

  console.log(filledData)

  const filteredData = filledData.filter((x: any) => x != null)
  const basic = filteredData.filter((x: any) => x.title === "Classic")
  const prime = filteredData.filter((x: any) => x.title === "Prime")
  const sortedData = {
    "Classic": {
      "total": basic.length,
      "ratio": Math.round(100 * basic.length / filteredData.length),
      "images": basic.map((b: any) => b.image),
      "thumbnails": basic.map((b: any) => b.thumbnail)
    },
    "Prime": {
      "total": prime.length,
      "ratio": Math.round(100 * prime.length / filteredData.length),
      "images": prime.map((b: any) => b.image),
      "thumbnails": prime.map((b: any) => b.thumbnail)
    }
  }

  const next = (callback: () => void) => {
    goToNext(filledData, callback)
  }

  return (
    <div className="h-full flex flex-col justify-center bg-slate-50 p-4 overflow-y-scroll">
      <AnimatePresence>
        {showSummary ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="w-full h-full flex flex-col items-center justify-center">
            <p className="sm:my-2 md:my-3 lg:my-5 text-sm sm:text-lg font-bold text-center">Thanks for helping us know your taste!<br />Here’s a list of all that you selected.</p>
            <div className="relative w-full flex flex-col lg:flex-row items-center justify-center gap-2 sm:gap-10 p-2 sm:px-4 md:px-8 px-10 lg:py-10">
              {basic.length > 0 && <ResultOption name="Daftar Classic" images={sortedData["Classic"]["thumbnails"]} percentage={sortedData["Classic"]["ratio"]} maxLength={Math.max(prime.length, basic.length)} desc="Bold designs, fine materials made affordable for modern needs." />}
              {prime.length > 0 && <ResultOption name="Daftar Prime" images={sortedData["Prime"]["thumbnails"]} percentage={sortedData["Prime"]["ratio"]} maxLength={Math.max(prime.length, basic.length)} desc="Premium aesthetics with practical function, for a unique office personality." />}
            </div>
            {/* <div className="hidden lg:block relative min-h-24 w-full px-20 flex flex-col justify-end">
              <div className="w-full flex flex-row justify-end">
                <NextButton next={next} isNextActive={true} />
              </div>
            </div> */}
          </motion.div>
        ) : (
          <>
            {(currentQuestion && imagesPreloaded) ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="relative quiz-question flex flex-col items-center justify-center">
                {/* <p className="my-8 text-xl font-bold">{currentQuestion.question}</p> */}
                <div className="relative w-full flex flex-col lg:flex-row justify-between gap-2 pl-2 pr-2 group">
                  {/* {upcomingQuestion?.data.choices.map((c: any) => <img key={c.image} src={c.image} alt={c.title} className="opacity-0 absolute" />)} */}
                  <AnimatePresence mode="wait">
                    {currentQuestion.data.choices.map((c: any, b: number) => (
                      <motion.div
                        initial={{ opacity: 0, x: (b === 0) ? -10 : 10, y: 10 }}
                        animate={{ opacity: .95, x: 0, y: 0 }}
                        exit={{ opacity: 0, x: (b === 0) ? -10 : 10, y: -50 }}
                        transition={{ duration: .2, ease: "easeInOut" }}
                        whileHover={{ opacity: 1, boxShadow: "inset 2px 2px 6px rgb(50,50,150)" }}
                        key={c.id}
                        className="relative flex-1 flex flex-col cursor-love rounded-lg"
                        // transition-all duration-1 hover-hover:group-hover:opacity-50 hover-hover:group-hover:hover:opacity-100"
                        onClick={() => {
                          setTapHeart(c.id)
                          setTimeout(() => {
                            setTapHeart(null)
                            nextQuestion({
                              title: c.title,
                              image: c.image,
                              thumbnail: c.thumbnail,
                              id: c.id
                            })
                          }, 150)
                        }}>
                        <img key={c.image} src={c.image} alt={c.title} className="object-cover aspect-4/3 w-full border border-slate-300 rounded-lg" />
                        <AnimatePresence>
                          {tapHeart === c.id && <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: -30 }}
                            exit={{ opacity: 0, y: -60 }}
                            transition={{ duration: 0.2 }}
                            className={classNames(
                              "absolute w-full h-full flex flex-row justify-center items-center"
                            )}>
                            <img src={loveImage} className="w-40 lg:w-52 xl:w-72 h-40 lg:h-52 xl:h-72 p-4" alt="tap-heart" />
                          </motion.div>}
                        </AnimatePresence>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                  <div className="absolute w-full h-full bottom-0 left-0 flex justify-center items-center transition-all duration-1 hover-none:hidden hover-hover:group-hover:opacity-0 hover-hover:group-hover:hover:opacity-100 pointer-events-none">
                    <img src={loveImage} className="w-20 h-20 lg:w-28 lg:h-28 p-4 lg:p-6 bg-white border-1 border-gray-500 rounded-full" alt="center-heart" />
                  </div>
                </div>
                {/* <div className="w-full flex flex-row justify-center gap-6 m-10">
            <div className="flex-1 flex flex-row justify-end">{currentQuestionIndex > 0 && <button onClick={() => { previousQuestion() }}><KeyboardDoubleArrowLeft /> Last Question</button>}</div>
            <div className="flex-1 flex flex-row justify-start"><button onClick={() => { nextQuestion(null) }}>Skip <KeyboardDoubleArrowRight /></button></div>
          </div> */}
              </motion.div>
            ) : (
              <Loader text="Building your personalized quiz" />
            )
            }
          </>
        )}
      </AnimatePresence>
    </div>
  )
}
