import LayoutImage from "../routes/project/addon-icons/layout-white@2x.png"
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ChairIcon from '@mui/icons-material/Chair';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const stages = [
  {
    slug: "layout",
    title: "Select Layout",
    icon: <img src={LayoutImage} alt="layout-icon" className="w-5 h-5" />
  }, {
    slug: "furniture",
    title: "Select Furniture",
    icon: <ChairIcon fontSize="small" />
  }, {
    slug: "treemap",
    title: "Space Builder",
    icon: <ViewComfyIcon fontSize="small" />
  }, {
    slug: "base-plan",
    title: "Base Plan",
    icon: <UploadFileIcon fontSize="small" />
  }
]

