import React, { forwardRef, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, Point, motion } from "framer-motion"
import { CheckBadgeIcon, ArrowSmallLeftIcon, ArrowSmallRightIcon } from "@heroicons/react/20/solid";

import MinimalLayoutImage from "../../media/layouts/web/Minimal.jpg"
import CorporateLayoutImage from "../../media/layouts/web/Corporate.jpg"
import BusinessCenterLayoutImage from "../../media/layouts/web/Business.jpg"
import CoworkingLayoutImage from "../../media/layouts/web/Coworking.jpg"
import LowPartitionLayoutImage from "../../media/layouts/web/Low Partition.jpeg"
import OpenPlanLayoutImage from "../../media/layouts/web/Open Plan.jpg"
import TeamOrientedLayoutImage from "../../media/layouts/web/Team oriented.jpg"
import CombinationLayoutImage from "../../media/layouts/web/Combination.jpg"
import NextButton from "../../components/nextButton";
import useImagePreloader from "../../utils/useImagePreloader";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import {
  setLayoutInput
} from "../../utils/formSlice";


const SingleLayout = (props: any, ref: any) => {
  return (
    <div ref={ref} className={classNames(props.className, "absolute bg-white rounded-md shadow-md m-20 opacity-20")}>
      <img key={props.key} alt="img3" src={props.img} className="aspect-3/4 object-cover rounded-[4rem]" />
    </div>
  )
}

const SingleLayoutRef = forwardRef(SingleLayout)
const MotionLayout = motion(SingleLayoutRef)

const layouts = [
  {
    id: 5,
    image: OpenPlanLayoutImage,
    name: "Open Plan"
  }, {
    id: 6,
    image: TeamOrientedLayoutImage,
    name: "Team Oriented"
  }, {
    id: 7,
    image: LowPartitionLayoutImage,
    name: "Low Partition"
  }, {
    id: 8,
    image: CombinationLayoutImage,
    name: "Combination"
  }, {
    id: 1,
    image: MinimalLayoutImage,
    name: "Minimal"
  }, {
    id: 2,
    image: CorporateLayoutImage,
    name: "Corporate"
  }, {
    id: 3,
    image: BusinessCenterLayoutImage,
    name: "Business Center"
  }, {
    id: 4,
    image: CoworkingLayoutImage,
    name: "Coworking Spaces"
  },
]

export default function LayoutSelectMobile({ goToNext }: { goToNext: (data: any, callback?: () => void) => void }) {

  const [selected, setSelected] = useState<number>(0)
  const [firstImage, setFirstImage] = useState<number>(0)
  const [secondImage, setSecondImage] = useState<number>(1)
  const [thirdImage, setThirdImage] = useState<number>(2)
  // const [fourthImage, setFourthImage] = useState<number>(3)
  // const [fifthImage, setFifthImage] = useState<number>(4)
  // const [nextIndex, setNextIndex] = useState(5)
  // const [previousIndex, setPreviousIndex] = useState(layouts.length - 1)

  const [direction, setDirection] = useState('right')
  // const [title, setTitle] = useState(layouts[0].name)

  const dispatch = useDispatch()
  
  const imagesLoaded = useImagePreloader(layouts.map(l => l.image))

  useEffect(() => {
    const layoutData = layouts.find(l => l.id === selected)
    dispatch(setLayoutInput(layoutData))
  }, [selected, dispatch])

  const nextLayout = useCallback(() => {
    setDirection("right")
    setSelected(layouts[secondImage].id)
    setFirstImage(secondImage)
    setSecondImage(thirdImage)
    setThirdImage((thirdImage + 1) === layouts.length ? 0 : thirdImage + 1)
  }, [secondImage, thirdImage])

  const previousLayout = useCallback(() => {
    setDirection("left")
    setThirdImage(secondImage)
    setSecondImage(firstImage)
    const i = (firstImage - 1) === -1 ? layouts.length - 1 : firstImage - 1
    setFirstImage(i)
    setSelected(layouts[i].id)
  }, [firstImage, secondImage])

  const swipeConfidenceThreshold = 100;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const slideVariantsFirst = {
    enter: (direction: string) => ({
      opacity: (direction === "right") ? 0.9 : 0,
      z: (direction === "right") ? 49 : 50,
      x: (direction === "right") ? 20 : 0,
      rotate: (direction === "right") ? 5 : 0
    }),
    visible: {
      opacity: 1,
      z: 50,
      x: 0,
      rotate: 0
    },
    exit: (direction: string) => ({
      opacity: (direction === "left") ? 0.9 : 0,
      z: (direction === "left") ? 49 : 50,
      x: (direction === "left") ? 20 : 0,
      rotate: (direction === "left") ? 5 : 0
    })
  }

  const slideVariantsSecond = {
    enter: (direction: string) => ({
      opacity: (direction === "right") ? 0.8 : 1,
      z: (direction === "right") ? 48 : 49,
      x: (direction === "right") ? 40 : 20,
      rotate: (direction === "right") ? 10 : 5
    }),
    visible: {
      opacity: 0.9,
      z: 49,
      x: 10,
      rotate: 5
    },
    exit: (direction: string) => ({
      opacity: (direction === "left") ? 0.8 : 1,
      z: (direction === "left") ? 48 : 49,
      x: (direction === "left") ? 40 : 20,
      rotate: (direction === "left") ? 10 : 5
    })
  }

  const slideVariantsThird = {
    enter: (direction: string) => ({
      opacity: (direction === "right") ? 0.7 : 0.9,
      z: (direction === "right") ? 47 : 48,
      x: (direction === "right") ? 60 : 40,
      rotate: (direction === "right") ? 15 : 10
    }),
    visible: {
      opacity: 0.8,
      z: 48,
      x: 20,
      rotate: 10
    },
    exit: (direction: string) => ({
      opacity: (direction === "left") ? 0.7 : 0.9,
      z: (direction === "left") ? 47 : 48,
      x: (direction === "left") ? 60 : 40,
      rotate: (direction === "left") ? 15 : 10
    })
  }

  return (
    <AnimatePresence>
      {!imagesLoaded ? (
        <Loader text={"Loading Layouts"} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: .5 }}
          className="h-full w-full lg:hidden"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={0}
          dragMomentum={false}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            console.log(swipe)
            if (swipe < -swipeConfidenceThreshold) {
              nextLayout();
            } else if (swipe > swipeConfidenceThreshold) {
              previousLayout()
            }
          }}
        >
          <div className="h-full flex flex-col justify-between gap-0 overflow-hidden">
            <div className="grow flex flex-col overflow-hidden w-full">
              <p className="my-5 text-lg font-bold text-center">Select a layout</p>
              <div className="h-fit grow flex flex-col xl:flex-row justify-start">
                <div className="relative basis-2/3 xl:basis-4/5">
                  {/*CANVAS */}
                  <div className="absolute top-0 left-0 right-0 botom-0 w-full h-full">
                    <div
                      className="absolute left-0 flex flex-col justify-center h-full w-fit">
                      <button className="relative w-8 h-8 bg-black text-white rounded-full"
                        onClick={()=>{previousLayout()}}><ArrowSmallLeftIcon /></button>
                    </div>
                    <AnimatePresence initial={false} custom={direction}>
                      <MotionLayout key={layouts[thirdImage].id} img={layouts[thirdImage].image} variants={slideVariantsThird} custom={direction} initial="enter" animate="visible" exit="exit" />
                      <MotionLayout key={layouts[secondImage].id} img={layouts[secondImage].image} variants={slideVariantsSecond} custom={direction} initial="enter" animate="visible" exit="exit" />
                      <MotionLayout key={layouts[firstImage].id} img={layouts[firstImage].image} variants={slideVariantsFirst} custom={direction} initial="enter" animate="visible" exit="exit" />
                    </AnimatePresence>
                    <div
                      className="absolute right-0 flex flex-col justify-center h-full w-fit">
                      <button className="w-8 h-8 bg-black text-white rounded-full" onClick={()=>{nextLayout()}}><ArrowSmallRightIcon /></button>
                    </div>
                  </div>
                </div>
                <div className="xl:basis-1/5 flex flex-col justify-center items-center">
                  <h1 className="font-bold text-xl">{layouts.find(l => l.id === selected)?.name}</h1>
                </div>
              </div>
            </div >
            {/* <div className="hidden lg:block relative shrink-0 h-24 w-full px-20 py-4">
              <div className="w-full flex flex-row justify-end">
                <NextButton next={next} isNextActive={true} />
              </div>
            </div> */}
          </div >
        </motion.div>
      )
      }
    </AnimatePresence >
  );
}