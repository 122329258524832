import cabin_1_basic from '../media/furniture/cabins/1_basic.jpg'
import cabin_1_prime from '../media/furniture/cabins/1_prime.jpg'
import cabin_2_basic from '../media/furniture/cabins/2_basic.jpg'
import cabin_2_prime from '../media/furniture/cabins/2_prime.jpg'
import chair_1_basic from '../media/furniture/chairs/1_basic.jpg'
import chair_1_prime from '../media/furniture/chairs/1_prime.jpg'
import chair_2_basic from '../media/furniture/chairs/2_basic.jpg'
import chair_2_prime from '../media/furniture/chairs/2_prime.jpg'
import chillzone_1_basic from '../media/furniture/chillzone/1_basic.jpg'
import chillzone_1_prime from '../media/furniture/chillzone/1_prime.jpg'
import chillzone_2_basic from '../media/furniture/chillzone/2_basic.jpg'
import chillzone_2_prime from '../media/furniture/chillzone/2_prime.jpg'
import conference_1_basic from '../media/furniture/conference/1_basic.jpg'
import conference_1_prime from '../media/furniture/conference/1_prime.jpg'
import conference_2_basic from '../media/furniture/conference/2_basic.jpg'
import conference_2_prime from '../media/furniture/conference/2_prime.jpg'
import reception_1_basic from '../media/furniture/reception/1_basic.jpg'
import reception_1_prime from '../media/furniture/reception/1_prime.jpg'
import reception_2_basic from '../media/furniture/reception/2_basic.jpg'
import reception_2_prime from '../media/furniture/reception/2_prime.jpg'
import workstation_1_basic from '../media/furniture/workstations/1_basic.jpg'
import workstation_1_prime from '../media/furniture/workstations/1_prime.jpg'
import workstation_2_basic from '../media/furniture/workstations/2_basic.jpg'
import workstation_2_prime from '../media/furniture/workstations/2_prime.jpg'

import cabin_1_basic_thumb from '../media/furniture-thumbnails/cabins/1_basic.jpg'
import cabin_1_prime_thumb from '../media/furniture-thumbnails/cabins/1_prime.jpg'
import cabin_2_basic_thumb from '../media/furniture-thumbnails/cabins/2_basic.jpg'
import cabin_2_prime_thumb from '../media/furniture-thumbnails/cabins/2_prime.jpg'
import chair_1_basic_thumb from '../media/furniture-thumbnails/chairs/1_basic.jpg'
import chair_1_prime_thumb from '../media/furniture-thumbnails/chairs/1_prime.jpg'
import chair_2_basic_thumb from '../media/furniture-thumbnails/chairs/2_basic.jpg'
import chair_2_prime_thumb from '../media/furniture-thumbnails/chairs/2_prime.jpg'
import chillzone_1_basic_thumb from '../media/furniture-thumbnails/chillzone/1_basic.jpg'
import chillzone_1_prime_thumb from '../media/furniture-thumbnails/chillzone/1_prime.jpg'
import chillzone_2_basic_thumb from '../media/furniture-thumbnails/chillzone/2_basic.jpg'
import chillzone_2_prime_thumb from '../media/furniture-thumbnails/chillzone/2_prime.jpg'
import conference_1_basic_thumb from '../media/furniture-thumbnails/conference/1_basic.jpg'
import conference_1_prime_thumb from '../media/furniture-thumbnails/conference/1_prime.jpg'
import conference_2_basic_thumb from '../media/furniture-thumbnails/conference/2_basic.jpg'
import conference_2_prime_thumb from '../media/furniture-thumbnails/conference/2_prime.jpg'
import reception_1_basic_thumb from '../media/furniture-thumbnails/reception/1_basic.jpg'
import reception_1_prime_thumb from '../media/furniture-thumbnails/reception/1_prime.jpg'
import reception_2_basic_thumb from '../media/furniture-thumbnails/reception/2_basic.jpg'
import reception_2_prime_thumb from '../media/furniture-thumbnails/reception/2_prime.jpg'
import workstation_1_basic_thumb from '../media/furniture-thumbnails/workstations/1_basic.jpg'
import workstation_1_prime_thumb from '../media/furniture-thumbnails/workstations/1_prime.jpg'
import workstation_2_basic_thumb from '../media/furniture-thumbnails/workstations/2_basic.jpg'
import workstation_2_prime_thumb from '../media/furniture-thumbnails/workstations/2_prime.jpg'
import axios from 'axios'


const FurnitureImage = {
    'MD': {
        'Classic': [{ image: cabin_1_basic, thumbnail: cabin_1_basic_thumb }, { image: cabin_2_basic, thumbnail: cabin_2_basic_thumb }],
        'Prime': [{ image: cabin_1_prime, thumbnail: cabin_1_prime_thumb }, { image: cabin_2_prime, thumbnail: cabin_2_prime_thumb }]
    },
    'Chair': {
        'Classic': [{ image: chair_1_basic, thumbnail: chair_1_basic_thumb }, { image: chair_2_basic, thumbnail: chair_2_basic_thumb }],
        'Prime': [{ image: chair_1_prime, thumbnail: chair_1_prime_thumb }, { image: chair_2_prime, thumbnail: chair_2_prime_thumb }]
    },
    'Chillzone': {
        'Classic': [{ image: chillzone_1_basic, thumbnail: chillzone_1_basic_thumb }, { image: chillzone_2_basic, thumbnail: chillzone_2_basic_thumb }],
        'Prime': [{ image: chillzone_1_prime, thumbnail: chillzone_1_prime_thumb }, { image: chillzone_2_prime, thumbnail: chillzone_2_prime_thumb }]
    },
    'Conference': {
        'Classic': [{ image: conference_1_basic, thumbnail: conference_1_basic_thumb }, { image: conference_2_basic, thumbnail: conference_2_basic_thumb }],
        'Prime': [{ image: conference_1_prime, thumbnail: conference_1_prime_thumb }, { image: conference_2_prime, thumbnail: conference_2_prime_thumb }]
    },
    'Reception': {
        'Classic': [{ image: reception_1_basic, thumbnail: reception_1_basic_thumb }, { image: reception_2_basic, thumbnail: reception_2_basic_thumb }],
        'Prime': [{ image: reception_1_prime, thumbnail: reception_1_prime_thumb }, { image: reception_2_prime, thumbnail: reception_2_prime_thumb }]
    },
    'Workstation': {
        'Classic': [{ image: workstation_1_basic, thumbnail: workstation_1_basic_thumb }, { image: workstation_2_basic, thumbnail: workstation_2_basic_thumb }],
        'Prime': [{ image: workstation_1_prime, thumbnail: workstation_1_prime_thumb }, { image: workstation_2_prime, thumbnail: workstation_2_prime_thumb }]
    }
}


export type CO = {
    slug: string,
    name?: string,
    question_override?: string
}

export type question = {
    id: string,
    kind: string,
    question: string,
    data: any
}

export const choice_order: Array<CO> = [{
    slug: "Workstation",
    name: "workstation",
}, {
    slug: "MD",
    name: "MD cabin",
}, {
    slug: "Reception",
    name: "Reception",
}, {
    slug: "Conference",
    name: "conference room",
}, {
    slug: "Chillzone",
    name: "office lounge",
}, {
    slug: "Chair",
    name: "workstation chair",
}]


const getFurnitureImages = async () => {
    let furnitureImages = null
    await axios.get("https://app.daftardecor.com/api/furnitureQuiz/random")
        .then(response => {
            furnitureImages = response?.data?.quiz
        }).catch(err => {
            console.log(err)
        })
    return furnitureImages
}

export const getFurnitureImagesAll = async () => {
    let furnitureImages = null
    await axios.get("https://app.daftardecor.com/api/furnitureQuiz/all")
        .then(response => {
            furnitureImages = response?.data?.quiz
        }).catch(err => {
            console.log(err)
        })
    return furnitureImages
}

export default getFurnitureImages