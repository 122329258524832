import { useMemo, useCallback, useRef, useEffect } from "react";
import FurnitureQuiz from "./furnitureQuiz";
import LayoutSelectMobile from "./layoutSelect3"
import LayoutSelect from "./layoutSelect2"
import LayoutTreemap from "./layoutTreemap"
import UploadFile from "./uploadFile";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  markComplete,
  setLayoutInput, setFurnitureInput, setTreemapInput, setUploadInput,
} from "../../utils/formSlice";

import { RootState } from "../../utils/store";


const ScrollFormElement = (props: any) => <div ref={props.innerRef} className={classNames(
  "relative w-full h-full bg-white lg:border-b-2 overflow-hidden snap-start snap-always",
  props.large && "h-fit min-h-full")}>{props.children}</div>

export default function FormOutlet({ setMapper }: { setMapper: (mapper: any) => void }) {

  const dispatch = useDispatch()

  const nextup = useSelector((state: RootState) => state.form.nextUp)
  const scrollLoc = useSelector((state: RootState) => state.form.scrollLoc)

  const layoutRef = useRef<HTMLElement | null>();
  const furnitureRef = useRef<HTMLElement | null>();
  const treemapRef = useRef<HTMLElement | null>();
  const rawAreaRef = useRef<HTMLElement | null>();

  const layoutNext = useCallback((data: any) => {
    dispatch(setLayoutInput(data))
    dispatch(markComplete("layout"))
  }, [dispatch])

  const furnitureNext = useCallback((data: any) => {
    dispatch(setFurnitureInput(data))
    dispatch(markComplete("furniture"))
  }, [dispatch])

  const treemapNext = useCallback((data: any) => {
    dispatch(setTreemapInput(data))
    dispatch(markComplete("treemap"))
  }, [dispatch])

  const basePlanNext = useCallback((data: any) => {
    dispatch(setUploadInput(data))
    dispatch(markComplete("base-plan"))
  }, [dispatch])

  const LS = useCallback(() => {
    return (
      <>
        <LayoutSelectMobile goToNext={layoutNext} />
        <LayoutSelect goToNext={layoutNext} />
      </>
    )
  }, [layoutNext])

  const mapping: any = useMemo(() => ({
    "layout": {
      next: layoutNext,
      element: <LS />,
      ref: layoutRef,
    },
    "furniture": {
      next: furnitureNext,
      element: <FurnitureQuiz goToNext={furnitureNext} />,
      ref: furnitureRef,
    },
    "treemap": {
      next: treemapNext,
      element: <LayoutTreemap goToNext={treemapNext} />,
      ref: treemapRef,
    },
    "base-plan": {
      next: basePlanNext,
      element: <UploadFile goToNext={basePlanNext} />,
      ref: rawAreaRef,
    }
  }), [
    LS,
    layoutRef, furnitureRef, treemapRef, rawAreaRef,
    layoutNext, furnitureNext, treemapNext, basePlanNext
  ])

  useEffect(() => {
    setMapper(mapping)
  }, [setMapper, mapping])


  return (
    <>
      {scrollLoc && <ScrollFormElement innerRef={mapping[scrollLoc].ref}>{mapping[scrollLoc].element}</ScrollFormElement>}
    </>
  )
}