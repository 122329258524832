import { useCallback, useEffect, useState } from "react"
import { choice_order, CO, getFurnitureImagesAll } from "../../utils/FurnitureImage"
import { preloadImage } from "../../utils/useImagePreloader"


export default function Test() {

    const [loaded, setLoadad] = useState(false)
    const [images, setImages] = useState([])
    const loadQuizImages = useCallback(() => {
        let rawQuiz: any = {}

        getFurnitureImagesAll().then((result: any) => {
            console.log(result)
            rawQuiz = result

            const imagesPromiseList: Promise<any>[] = []
            for (const i of result) {
                imagesPromiseList.push(preloadImage(i.url))
            }

            console.log(imagesPromiseList)

            Promise.all(imagesPromiseList)
                .then(success => {
                    console.log("loaded images")
                    setLoadad(true)
                    setImages(result)
                })
                .catch(error => {
                    console.log("unable to load images")
                    console.log(error)
                })
        })
    }, [])

    useEffect(() => {
        loadQuizImages()
    }, [loadQuizImages])

    return (
        <div>
            <p>{loaded ? "loaded" : "x"}</p>
            {images.map((i : any) => <img src={i.url} key={i.key} alt={i.id}/>)}
        </div>
    )
}