import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion"
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

import MinimalLayoutImage from "../../media/layouts/web/Minimal.jpg"
import CorporateLayoutImage from "../../media/layouts/web/Corporate.jpg"
import BusinessCenterLayoutImage from "../../media/layouts/web/Business.jpg"
import CoworkingLayoutImage from "../../media/layouts/web/Coworking.jpg"
import LowPartitionLayoutImage from "../../media/layouts/web/Low Partition.jpeg"
import OpenPlanLayoutImage from "../../media/layouts/web/Open Plan.jpg"
import TeamOrientedLayoutImage from "../../media/layouts/web/Team oriented.jpg"
import CombinationLayoutImage from "../../media/layouts/web/Combination.jpg"
import NextButton from "../../components/nextButton";
import useImagePreloader from "../../utils/useImagePreloader";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import {
  setLayoutInput
} from "../../utils/formSlice";


const layouts = [
  {
    id: 5,
    image: OpenPlanLayoutImage,
    name: "Open Plan"
  }, {
    id: 6,
    image: TeamOrientedLayoutImage,
    name: "Team Oriented"
  }, {
    id: 7,
    image: LowPartitionLayoutImage,
    name: "Low Partition"
  }, {
    id: 8,
    image: CombinationLayoutImage,
    name: "Combination"
  }, {
    id: 1,
    image: MinimalLayoutImage,
    name: "Minimal"
  }, {
    id: 2,
    image: CorporateLayoutImage,
    name: "Corporate"
  }, {
    id: 3,
    image: BusinessCenterLayoutImage,
    name: "Business Center"
  }, {
    id: 4,
    image: CoworkingLayoutImage,
    name: "Coworking Spaces"
  },
]

export default function LayoutSelect({ goToNext }: { goToNext: (data: any, callback?: () => void) => void }) {


  const dispatch = useDispatch()

  const [selected, setSelected] = useState<number|null>(null)
  const imagesLoaded = useImagePreloader(layouts.map(l => l.image))


  useEffect(() => {
    const layoutData = layouts.find(l => l.id === selected)
    dispatch(setLayoutInput(layoutData))
  }, [selected, dispatch])

  // const next = (callback: () => void) => {
  //   const layoutData = layouts.find(l => l.id === selected)
  //   if (layoutData) goToNext(layoutData, callback)
  // }

  return (
    <AnimatePresence>
      {!imagesLoaded ? (
        <Loader text={""} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: .5 }}
          className="h-full w-full hidden lg:block"
        >
          <div className="h-full flex flex-col justify-between gap-0 overflow-hidden">
            <div className="grow flex flex-col justify-start overflow-hidden min-h-0">
              <p className="my-5 text-lg font-bold text-center">Select a layout</p>
              <div className={classNames(
                "block", //default grid
                "lg:hidden", // hide on lg+ screens
                "lg:hover-none:block", // show on lg+ screens with no hover
                "overflow-scroll no-scrollbar"
              )}>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 p-2 ">
                  {layouts.map((l) => (
                    <motion.div
                      whileTap={{ scale: 0.9 }}
                      key={l.id}
                      onClick={() => { setSelected(l.id) }}
                      className={classNames(
                        "relative transition-all duration-500 m-0 leading-0 border border-gray-200 shadow-md rounded-lg overflow-hidden select-none"
                      )}
                    >
                      <div className="w-full h-full">
                        <img src={l.image} alt={l.name} className="w-auto object-contain" />
                        <div className="w-full bg-gray-100 border-left-1 border-right-1 border-gray-300 h-12 rounded-b-lg">
                          <div className="h-full w-full flex flex-row justify-center items-center text-xs">
                            {selected === l.id && <CheckBadgeIcon className="w-6 h-6 text-blue-500" />}
                            <p className={classNames(
                              "text-center",
                              (selected === l.id) && "font-bold text-blue-500"
                            )}>{l.name}</p>
                          </div>
                        </div>
                        {selected === l.id && <div className="absolute top-0 left-0 w-full h-full bg-blue-500/10 rounded-lg" />}
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
              <div className={classNames(
                "hidden", // default hidden
                "hover-hover:lg:flex", // show on lg+ screens where hover is possible
                "grow w-full py-4 px-2 box-border h-full group/accordion overflow-hidden")}>
                {layouts.map((l) => (
                  <div
                    key={l.id}
                    onClick={() => { setSelected(l.id) }}
                    className={classNames(
                      "relative flex-1 overflow-hidden transition-all delay-150 duration-500 m-0 leading-0 group/item cursor-pointer",
                      "hover:flex-accordion-zoom hover:px-1",
                    )}
                  >
                    <div className="relative w-full h-[calc(100%-3rem)] flex justify-center items-center group-hover/accordion:opacity-50 group-hover/accordion:hover:opacity-100">
                      <img src={l.image} alt={l.name} className="h-full max-w-full max-h-full object-cover !aspect-accordion-image" />
                    </div>
                    <div className="w-full bg-gray-100 border-left-1 border-right-1 border-gray-300 h-12">
                      <div className="h-full w-full flex flex-row justify-center items-center text-xs transition-all delay-150 duration-500 group-hover/accordion:opacity-0 group-hover/accordion:group-hover/item:opacity-100 group-hover/accordion:group-hover/item:text-lg">
                        {selected === l.id && <CheckBadgeIcon className="w-6 h-6 text-blue-500" />}
                        <p className={classNames(
                          "text-center",
                          (selected === l.id) && "font-bold text-blue-500"
                        )}>{l.name}</p>
                      </div>
                    </div>
                    {selected === l.id && <div className="absolute top-0 left-0 w-full h-full bg-blue-500/10" />}

                  </div>
                ))}
              </div>
            </div >
            {/* <div className="hidden lg:block relative shrink-0 h-24 w-full px-20 py-4">
              <div className="w-full flex flex-row justify-end">
                <NextButton next={next} isNextActive={true} />
              </div>
            </div> */}
          </div >
        </motion.div>
      )}
    </AnimatePresence>
  );
}