import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { clarity } from 'clarity-js';
import { useEffect } from "react";

const SRV_URL = "https://app.daftardecor.com"

const emailCheck = (email: string) => {
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
  return re.test(email)
}

const phoneCheck = (phone: string) => {
  const re = /^[6-9]\d{9}$/
  return re.test(phone)
}

// const areaCheck = (area: string) => {
//   const re = /^\d*$/
//   return re.test(area)
// }


const SingleInput = ({ title, error, defaultValue, setter, addon, converter, inputMode, mandatory, maxLength }: { error?: Boolean, inputMode?: 'text' | 'numeric', title: string, defaultValue: any, mandatory?: boolean, setter: (arg0: any) => void, addon?: string, converter?: (arg0: string) => any, maxLength?: any }) => {
  return (
    <div className="w-full">
      <div className={`flex flex-row items-center w-full justify-start px-4 py-1 bg-white border ${error && "border-red-600"} rounded-md text-slate-950 min-w-56 md:w-fit`}>
        <input
          type="text"
          className="border-0 text-lg text-slate-950 py-1.5 pl-1 w-full outline-0 focus:ring-0 focus:ring-offset-0 bg-white"
          defaultValue={`${defaultValue}`}
          inputMode={inputMode}
          placeholder={`${title}${mandatory ? "*" : ""}`}
          onChange={(event) => {
            setter(converter ? converter(event.target.value) : event.target.value)
          }}
          maxLength={maxLength}
        />
        {addon && <span>{addon}</span>}
      </div>
    </div>
  )
}

const TablistInput = ({ title, options, value, setter, mandatory }: { title: string, options: Array<{ title: string, slug: string }>, value: any, mandatory?: boolean, setter: (arg0: any) => void }) => {
  return (
    <div>
      {/* <p className="mb-1">{title}{mandatory && "*"}</p> */}
      <div>
        <ul className="flex flex-row text-center text-gray-600 bg-gray-100 border rounded-md p-1 min-w-56 md:w-[15.5rem]">
          {options.map(o => (
            <li key={o.slug} className="grow">
              <button className={classNames("w-full flex justify-center py-2", value === o.slug && "bg-white rounded-md shadow text-indigo-800")} onClick={() => {
                setter(o.slug)
              }}>{o.title}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


export default function New() {

  const loaderData: any = useLoaderData();
  console.log(loaderData)
  const userId = loaderData?.data?.user?.id

  const [nexting, setNexting] = useState(false)

  const [carpetArea, setCarpetArea] = useState<number | null>(null)
  const [carpetAreaError, setCarpetAreaError] = useState<Boolean>(false)
  const [name, setName] = useState<string>("")
  const [companyName, setCompanyName] = useState<string>("")
  const [website, setWebsite] = useState<string>("")
  const [location, setLocation] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>(loaderData?.data?.user?.phone ?? "")
  const [kind, setKind] = useState<string>("NEW")

  const next = () => {

    if (email.length === 0 || companyName.length === 0 || carpetArea === null || carpetArea === undefined) {
      alert("Please fill all mandatory fields")
      return
    }
    if (!emailCheck(email)) {
      alert("Invalid Email")
      return
    }
    if (!userId && !phoneCheck(phone)) {
      alert("Invalid Phone Number")
      return
    }

    if (carpetArea < 1000) {
      setCarpetAreaError(true)
      return
    }

    const data: any = {
      carpetArea,
      companyName,
      location,
      website,
      email,
      kind
    }
    if (userId) data.userId = userId
    else data.phone = phone

    setNexting(true)

    axios.post(`${SRV_URL}/api/projects`, data).then(response => {
      console.log(response)
      window.location.href = `${process.env.PUBLIC_URL}/project/${response.data.project.id}`
    }).catch(error => {
      console.log(error)
      setNexting(false)
    })
  }

  useEffect(() => {
    clarity.start({
      projectId: 'jz4rbce2x0',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
    })
    return () => {
      clarity.stop()
    }
  }, [])

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-2 xl:gap-8 bg-[url('./media/bg.jpg')] bg-cover p-2 xl:p-8">
      <div className="flex flex-col items-center w-full max-h-screen gap-2 p-2 shadow-2xl bg-white/50 backdrop-blur-sm rounded-2xl md:p-8 xl:gap-8 h-fit md:w-fit md:h-fit">
        <img src="/logo-light.png" alt="daftardecor logo" className="block w-32 p-2 xl:w-64 xl:p-4" />
        <div>
          <h1 className="mb-4 text-xl font-semibold text-center">Create your Throne</h1>
        </div>
        <div className="flex flex-col w-full gap-2 px-3 sm:px-10 md:px-0 grow md:gap-8">
          <div className="flex flex-col gap-2 md:flex-row md:gap-8">
            <SingleInput title="Name" defaultValue={name} setter={setName} mandatory={true} />
            {(!userId) && <SingleInput inputMode="numeric" title="Phone" maxLength="10" defaultValue={phone} setter={setPhone} mandatory={true} />}
          </div>
          <div className="flex flex-col gap-2 md:flex-row md:gap-8">
            <SingleInput title="Company Name" defaultValue={companyName} setter={setCompanyName} mandatory={true} />
            <SingleInput title="Location" defaultValue={location} setter={setLocation} />
          </div>
          <div className="flex flex-col gap-2 md:flex-row md:gap-8">
            <SingleInput title="Email" defaultValue={email} setter={setEmail} mandatory={true} />
            <SingleInput title="Website" defaultValue={website} setter={setWebsite} />
          </div>
          <div className="relative flex flex-col gap-2 h-fit md:flex-row md:gap-8">
            <SingleInput title="Carpet Area (sqft.)" error={carpetAreaError} inputMode="numeric" defaultValue={carpetArea !== null ? carpetArea : ""} setter={setCarpetArea} converter={parseInt} mandatory={true} />
            <div className={`absolute ${carpetAreaError ? "flex" : "hidden"} text-[0.85rem] text-red-700 w-full rounded-md md:top-[120%] top-[110%]`}>
              We are currently not servicing area under 1000 SqFt, we apologize for any inconvenience.
            </div>
            <TablistInput title="Purpose" value={kind} setter={setKind} options={[{ title: "New Office", slug: "NEW" }, { title: "Renovation", slug: "RENOVATION" }]} mandatory={true} />
          </div>
        </div>
        <button className="w-56 px-3 py-3 my-4 mt-12 text-lg font-semibold text-white transition-all bg-indigo-600 shadow-sm text-md md:mt-10 lg:mt-10 xl:mt-4 md:w-full rounded-xl hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={next} disabled={nexting}>{nexting ? "Please wait" : "Next"}</button>
      </div>
    </div >
  );
}