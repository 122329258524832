import React, { useEffect, useState } from "react"


export default function StepCounter({
  title,
  defaultValue,
  maxValue,
  onChange,
  tags
}: {
  title: string,
  defaultValue?: number,
  maxValue?: number,
  onChange: (value: number) => void,
  tags?: string[]
}) {
  const [value, setValue] = useState(defaultValue ?? 0)

  const increment = () => {
    const newValue = maxValue ? ((value + 1 <= maxValue) ? (value + 1) : value) : value + 1
    setValue(newValue)
    onChange(newValue)
  }

  const decrement = () => {
    const newValue = value - 1 >= 0 ? value - 1 : 0
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div className="custom-number-input min-w-[130px] flex flex-col">
      <div>
        <label htmlFor="custom-input-number" className="w-full text-gray-700 text-sm font-semibold">
          {title}
        </label>
        {tags && (tags.length > 0) &&
          <p className="text-xs font-bold m-0">
            {tags.join(", ")}
          </p>
        }
      </div>
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1 w-32">
        <button onClick={decrement} data-action="decrement" className="flex flex-col justfify-center items-center bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-l cursor-pointer outline-none border-r">
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <span className="outline-none focus:outline-none text-center w-full bg-gray-200 font-semibold text-md hover:text-black focus:text-black md:text-base cursor-default flex justify-center items-center text-gray-700 outline-none">{value}</span>
        <button onClick={increment} data-action="increment" className="flex flex-col justfify-center items-center bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-r cursor-pointer outline-none border-l">
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  )
}