import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { stages } from '../data/form'
import axios from 'axios'
import _ from 'lodash'
import { question } from './FurnitureImage'


const SRV_URL = "https://app.daftardecor.com"

const stageSlugs = stages.map(s => s.slug)

const convertProxyObjectToPojo = (proxyObj: any) => {
  return _.cloneDeep(proxyObj);
}

export interface FormState {
  projectId: string | null,
  carpetArea: number,
  complete: Array<string>,
  nextUp: string | null,
  scrollLoc: string,
  processingLayoutInput: boolean,
  processingFurnitureInput: boolean,
  processingTreemapInput: boolean,
  processingUploadInput: boolean,
  layoutInput: any,
  furnitureInput: Array<any>,
  treemapInput: any,
  uploadInput: any,
  furnitureImagesPreloaded: boolean,
  furntitureQuizQuestions: Array<question>
}

const initialState: FormState = {
  projectId: null,
  carpetArea: 2000,
  complete: [],
  nextUp: "layout",
  scrollLoc: "layout",
  processingLayoutInput: false,
  processingFurnitureInput: false,
  processingTreemapInput: false,
  processingUploadInput: false,
  layoutInput: null,
  furnitureInput: [],
  treemapInput: {},
  uploadInput: null,
  furnitureImagesPreloaded: false,
  furntitureQuizQuestions: []
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setQuizImagesLoaded: (state, action: PayloadAction<boolean>) => {
      state.furnitureImagesPreloaded = action.payload
    },
    setFurnitureQuizQuestions: (state, action: PayloadAction<Array<question>>) => {
      state.furntitureQuizQuestions = action.payload
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload
      console.log(action.payload)
    },
    globalNextTrigger: (state, action: PayloadAction<(() => void)>) => {
      const stage = state.scrollLoc
      if (!state.complete.includes(stage))
        state.complete.push(stage)

      const pending = stageSlugs.filter(s => !(state.complete.includes(s)))
      state.nextUp = pending.length > 0 ? pending[0] : null
      if (stage === "layout") {
        state.scrollLoc = "furniture"
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          layoutSelection: state.layoutInput
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "furniture") {
        state.scrollLoc = "treemap"
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          furnitureSelection: state.furnitureInput
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "treemap") {
        state.scrollLoc = "base-plan"
        const spaceConfigRaw = state.treemapInput
        const treemapInput = convertProxyObjectToPojo(state.treemapInput)
        const keys = Object.keys(treemapInput).filter((x: string) => (x !== "hidden"))
        console.log(treemapInput)
        console.log(keys)
        const spaceArray = keys.map(k => treemapInput[k])
        console.log(spaceArray)
        const spaceConfig: Array<any> = spaceArray.reduce((acc: Array<any>, cur: any) => {
          const data: Array<any> = cur.filter((x: any) => x.units > 0).map(
            (x: any) => (
              { name: x.name, units: x.units, tags: x.tags, category: x.category }
            )
          )
          console.log(data)
          acc.push(data)
          return acc
        }, [])
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          spaceConfig,
          spaceConfigRaw
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "base-plan") {
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          basePlan: null,
          submitted: true
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
          window.location.href = `https://app.daftardecor.com/project/${response.data.project.id}/confirmation`
        }).catch((err) => {
          console.log(err)
        })
      }

      action.payload()
    },
    markComplete: (state, action: PayloadAction<string>) => {
      const stage = action.payload
      if (!state.complete.includes(stage))
        state.complete.push(stage)
      const pending = stageSlugs.filter(s => !(state.complete.includes(s)))
      state.nextUp = pending.length > 0 ? pending[0] : null
      if (stage === "layout") {
        state.scrollLoc = "furniture"
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          layoutSelection: state.layoutInput
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "furniture") {
        state.scrollLoc = "treemap"
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          furnitureSelection: state.furnitureInput
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "treemap") {
        state.scrollLoc = "base-plan"
        const spaceConfigRaw = state.treemapInput
        const treemapInput = convertProxyObjectToPojo(state.treemapInput)
        const keys = Object.keys(treemapInput).filter((x: string) => (x !== "hidden"))
        console.log(treemapInput)
        console.log(keys)
        const spaceArray = keys.map(k => treemapInput[k])
        console.log(spaceArray)
        const spaceConfig: Array<any> = spaceArray.reduce((acc: Array<any>, cur: any) => {
          const data: Array<any> = cur.filter((x: any) => x.units > 0).map(
            (x: any) => (
              { name: x.name, units: x.units, tags: x.tags, category: x.category }
            )
          )
          console.log(data)
          acc.push(data)
          return acc
        }, [])
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          spaceConfig,
          spaceConfigRaw
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
        }).catch((err) => {
          console.log(err)
        })
      }
      else if (stage === "base-plan") {
        axios.patch(`${SRV_URL}/api/project/${state.projectId}`, {
          basePlan: null,
          submitted: true
        }).then((response) => {
          console.log("uploaded")
          console.log(response)
          window.location.href = `https://app.daftardecor.com/project/${response.data.project.id}/confirmation`
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    markCompleteWithData: (state, action: PayloadAction<{ stage: string, data: any }>) => {
      const { data, stage } = action.payload
      if (!state.complete.includes(stage))
        state.complete.push(stage)

      const pending = stageSlugs.filter(s => !(state.complete.includes(s)))
      state.nextUp = pending.length > 0 ? pending[0] : null
      if (stage === "layout") {
        state.scrollLoc = "furniture"
        state.layoutInput = data
      }
      else if (stage === "furniture") {
        state.scrollLoc = "treemap"
        state.furnitureInput = data
      }
      else if (stage === "treemap") {
        state.scrollLoc = "base-plan"
        state.treemapInput = data
      }
      else if (stage === "base-plan") {
        state.uploadInput = data
      }
    },
    markIncomplete: (state, action: PayloadAction<string>) => {
      state.complete = state.complete.filter(x => x !== action.payload)
    },
    scrollTo: (state, action: PayloadAction<string>) => {
      state.scrollLoc = action.payload
    },
    setProcessingLayoutInput: (state, action: PayloadAction<boolean>) => {
      state.processingLayoutInput = action.payload
    },
    setProcessingFurnitureInput: (state, action: PayloadAction<boolean>) => {
      state.processingFurnitureInput = action.payload
    },
    setProcessingTreemapInput: (state, action: PayloadAction<boolean>) => {
      state.processingTreemapInput = action.payload
    },
    setProcessingUploadInput: (state, action: PayloadAction<boolean>) => {
      state.processingUploadInput = action.payload
    },
    setLayoutInput: (state, action: PayloadAction<any>) => {
      state.layoutInput = action.payload
    },
    setFurnitureInput: (state, action: PayloadAction<any>) => {
      state.furnitureInput = action.payload
    },
    setTreemapInput: (state, action: PayloadAction<any>) => {
      state.treemapInput = action.payload
    },
    setUploadInput: (state, action: PayloadAction<any>) => {
      state.uploadInput = action.payload
    },
    setCarpetArea: (state, action: PayloadAction<any>) => {
      state.carpetArea = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setProjectId,
  setQuizImagesLoaded, setFurnitureQuizQuestions,
  scrollTo, globalNextTrigger,
  markComplete, markIncomplete, markCompleteWithData,
  setLayoutInput, setFurnitureInput, setTreemapInput, setUploadInput,
  setCarpetArea,
} = formSlice.actions

export default formSlice.reducer