import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { stages } from "../../data/form";
import FormOutlet from "./FormOutlet";
import { RootState } from "../../utils/store";
import { useDispatch, useSelector } from "react-redux";
import { scrollTo, setCarpetArea, globalNextTrigger, setProjectId, setQuizImagesLoaded, setFurnitureQuizQuestions } from "../../utils/formSlice";
import { useLoaderData } from "react-router-dom";
import NextButton from "../../components/nextButton"
import DoneIcon from '@mui/icons-material/Done';
import getFurnitureImages, { CO, choice_order, question } from '../../utils/FurnitureImage'
import { preloadImage } from "../../utils/useImagePreloader"
import { clarity } from 'clarity-js';


const Icon = (icon: any, color: any, size: "small" | "inherit" | "large" | "medium" = "small") => <Icon fontSize={size} sx={{ color }} />


export default function ProjectContainer() {

  const loaderData: any = useLoaderData();
  console.log(loaderData)

  const [navList, setNavList] = useState(stages.map(n => ({ ...n, isActive: false })))

  const complete = useSelector((state: RootState) => state.form.complete)
  const nextUp = useSelector((state: RootState) => state.form.nextUp)
  const [mapper, setMapper] = useState(null)


  const dispatch = useDispatch()


  const loadQuizImages = useCallback(() => {
    let rawQuiz: any = {}

    getFurnitureImages().then((result: any) => {
      console.log(result)
      rawQuiz = result

      const imagesToPreload: Array<string> = choice_order.reduce((acc: Array<string>, cur: CO) => {
        acc.push(
          rawQuiz[cur.slug].Classic[0].url,
          rawQuiz[cur.slug].Prime[0].url,
        )
        if (rawQuiz[cur.slug].Classic.length > 1) {
          acc.push(
            rawQuiz[cur.slug].Prime[1].url,
            rawQuiz[cur.slug].Classic[1].url
          )
        }

        return acc
      }, [])

      console.log(imagesToPreload)
      const imagesPromiseList: Promise<any>[] = []
      for (const i of imagesToPreload) {
        imagesPromiseList.push(preloadImage(i))
      }

      console.log(imagesPromiseList)

      Promise.all(imagesPromiseList)
        .then(success => {
          console.log("loaded images")
          dispatch(setQuizImagesLoaded(true))
        })
        .catch(error => {
          console.log("unable to load images")
          console.log(error)
        })

      const qlist: Array<question> = choice_order.reduce((accumulator: Array<question>, currentValue: CO) => {
        console.log(rawQuiz[currentValue.slug])
        const q1 = {
          id: `${currentValue.slug}_1`,
          kind: "select-one",
          question: currentValue.question_override ? currentValue.question_override : `Choose your ${currentValue.name} design style`,
          data: {
            choices: [
              {
                title: "Classic",
                image: rawQuiz[currentValue.slug].Classic[0].url,
                thumbnail: rawQuiz[currentValue.slug].Classic[0].url,
                id: rawQuiz[currentValue.slug].Classic[0].id
              },
              {
                title: "Prime",
                image: rawQuiz[currentValue.slug].Prime[0].url,
                thumbnail: rawQuiz[currentValue.slug].Prime[0].url,
                id: rawQuiz[currentValue.slug].Prime[0].id
              }
            ]
          }
        }
        accumulator.push(q1)

        if (rawQuiz[currentValue.slug].Classic.length > 1) {
          const q2 = {
            id: `${currentValue.slug}_2`,
            kind: "select-one",
            question: currentValue.question_override ? currentValue.question_override : `Choose your ${currentValue.name} design style`,
            data: {
              choices: [
                {
                  title: "Classic",
                  image: rawQuiz[currentValue.slug].Classic[1].url,
                  thumbnail: rawQuiz[currentValue.slug].Classic[1].url,
                  id: rawQuiz[currentValue.slug].Classic[1].id
                },
                {
                  title: "Prime",
                  image: rawQuiz[currentValue.slug].Prime[1].url,
                  thumbnail: rawQuiz[currentValue.slug].Prime[1].url,
                  id: rawQuiz[currentValue.slug].Prime[1].id
                }
              ]
            }
          }
          accumulator.push(q2)
        }
        return accumulator
      }, [])
      dispatch(setFurnitureQuizQuestions(qlist))
    }).catch(err => {
      rawQuiz = null
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(setCarpetArea(loaderData?.data?.project?.carpetArea))
    dispatch(setProjectId(loaderData?.data?.project?.id))
    loadQuizImages()
    clarity.start({
      projectId: 'jz4rbce2x0',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
    })
    return () => {
      clarity.stop()
    }
  }, [dispatch, loaderData, loadQuizImages])


  const currentInView = (x: Array<string>) => {
    const active = x.length > 0 ? x[0] : null
    if (active) setNavList(navList.map(n => ({ ...n, isActive: n.slug === active })))
  }

  const scrollInOutlet = (slug: string) => {
    dispatch(scrollTo(slug))
  }

  const next = (callback: () => void) => {
    dispatch(globalNextTrigger(callback))
  }

  return (
    <div className="relative flex flex-col !h-screen min-h-0">
      <div className="relative flex flex-row w-full h-[calc(100%-4rem)] bg-white lg:bg-gray-200 sm:px-4 lg:px-0">
        {/* <div className="hidden lg:flex flex-col justify-center lg:justify-between min-w-52 shrink-0">
          <img src="/logo-light.png" alt="daftardecor logo" className="hidden lg:block w-52 p-4" />
          <ul className="flex flex-col px-6 lg:ml-4">
            {navList.map((n, i) => {
              const isComplete = complete.includes(n.slug)
              return (
                <li key={n.slug} className={classNames("nav-item-container", isComplete && "complete", n.isActive && "active", nextUp === n.slug && "nextup")}>
                  <button className="nav-item" onClick={() => { scrollInOutlet(n.slug) }}>
                    <div className={classNames("nav-item-step", isComplete && "&>*:text-slate-800")}>{isComplete ? <DoneIcon fontSize="small" /> : n.icon}</div>
                    <p className={classNames("hidden lg:block nav-item-title w-28 text-sm", n.isActive && "font-semibold")}>{n.title}</p>
                  </button>
                </li>
              )
            })}
          </ul>
          <span className="opacity-0 hidden lg:block">Cargaison Brands Pvt. Ltd.</span>
        </div> */}
        <div className="relative grow overflow-y-scroll scroll-smooth no-scrollbar snap-y snap-mandatory">
          <FormOutlet setMapper={setMapper}/>
          {/* <ScrollFormOutlet currentInView={currentInView} /> */}
        </div>
      </div>
      <div className="flex flex-row justify-around items-center h-24">
        <ul className="flex flex-row justify-center p-0">
          {navList.map((n, i) => {
            const isComplete = complete.includes(n.slug)
            return (
              <li key={n.slug} className={classNames("nav-item-container flex justify-center items-center", isComplete && "complete", n.isActive && "active", nextUp === n.slug && "nextup")}>
                <button className="nav-item nav-item-horizontal w-16 h-16" onClick={() => { scrollInOutlet(n.slug) }}>
                  <div className={classNames("nav-item-step nav-item-step-horizontal", isComplete && "&>*:text-slate-800")}>{isComplete ? <DoneIcon fontSize="small" /> : n.icon}</div>
                </button>
                {/* <span className="hidden lg:block">{n.title}</span> */}
              </li>
            )
          }
          )}
        </ul>
        <NextButton next={next} isNextActive={true} finish={complete.includes("treemap")}/>
      </div>
    </div>
  );
}