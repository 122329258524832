import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error";
import Root from './routes/root';
import ProjectContainer from './routes/project/container';
import New from './routes/project/new';
import Status from './routes/status';
import { store } from './utils/store'
import { Provider } from 'react-redux'
import axios from 'axios';
import FurnitureQuiz from './routes/project/furnitureQuiz';
import Summary from './routes/project/summary';
import Confirmation from './routes/project/confirmation';
import Test from './routes/project/test';

// const SRV_URL = process.env.PUBLIC_URL
const SRV_URL = "https://app.daftardecor.com"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Status />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <Root />,
        errorElement: <ErrorPage />
      }, {
        path: "projects/new",
        element: <Root />,
        errorElement: <ErrorPage />
      }
    ]
  }, {
    path: "/new",
    loader: ({ params }) => {
      return null
    },
    element: <New />,
    errorElement: <ErrorPage />,
  }, {
    path: "/u/:userId/new",
    loader: ({ params }) => {
      return axios.get(`${SRV_URL}/api/user/${params.userId}`);
    },
    element: <New />,
    errorElement: <ErrorPage />,
  }, {
    path: "/project/:projectId",
    loader: ({ params }) => {
      return axios.get(`${SRV_URL}/api/project/${params.projectId}`);
    },
    element: <ProjectContainer />,
    errorElement: <ErrorPage />,
  }, {
    path: "/project/:projectId/summary",
    loader: ({ params }) => {
      return axios.get(`${SRV_URL}/api/project/${params.projectId}`);
    },
    element: <Summary />,
    errorElement: <ErrorPage />,
  }, {
    path: "/project/:projectId/confirmation",
    loader: ({ params }) => {
      return axios.get(`${SRV_URL}/api/project/${params.projectId}`);
    },
    element: <Confirmation />,
    errorElement: <ErrorPage />,
  }, {
    path: "/test",
    element: <Test />,
    errorElement: <ErrorPage />,
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="absolute inset-0">
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
