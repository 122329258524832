import React, { useState, DragEvent, ChangeEvent, useEffect, useCallback } from "react";
import axios from "axios";
import NextButton from "../../components/nextButton"
import { useDispatch, useSelector } from "react-redux";
import {
  setUploadInput
} from "../../utils/formSlice";

import { RootState } from "../../utils/store";


export default function UploadFile({ goToPrevious, goToNext }: { goToPrevious?: () => void, goToNext: (data: any, callback?: () => void) => void }) {

  const [file, setFile] = useState<any>(undefined)
  const [uploading, setUploading] = useState(false)
  const dispatch = useDispatch()
  const basePlanUrl = useSelector((state: RootState) => state.form.uploadInput)
  const setBasePlanUrl = useCallback((x: any) => {dispatch(setUploadInput(x))}, [dispatch])

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }
  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer && event.dataTransfer.files.length !== 0) {
      const files = event.dataTransfer.files
      setUploading(true)
      setFile(files[0]);
    } else {
      alert("An error occurred")
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setUploading(true)
      setFile(event.target.files[0]);
    } else {
      alert("An error occurred")
    }
  }

  useEffect(() => {
    console.log(file)
    if (file) {
      axios.post("/api/getPresignedUrl", {
        filename: file.name,
        userId: "new"
      }).then(response => {
        console.log(response, response.status, response.data)
        if (response.status === 200) {
          const { url, fileUrl } = response.data
          axios.put(url, file).then(response2 => {
            console.log(response2)
            setBasePlanUrl(fileUrl)
            setUploading(false)
          }).catch(err2 => {
            console.log(err2)
            setUploading(false)
            setFile(null)
            alert("An upload error occurred")
          })
        }
      }).catch(err => {
        console.log(err)
        setUploading(false)
        setFile(null)
        alert("An upload error occurred")
      })
    }
  }, [file, setBasePlanUrl])

  const next = (callback: () => void) => {
    goToNext({
      url: basePlanUrl
    }, callback)
  }
  return (
    <div className="h-full w-full p-10 flex flex-col justify-between items-center">
      <div className="w-full">
        <h3 className="font-bold text-center">Upload Base Plan File</h3>
        <p className="text-center">Please upload a layout plan that outlines the area you wish to build</p>
        <div className="flex items-center justify-center w-full mt-10" onDrop={onDrop} onDragOver={onDragOver}>
          <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {uploading ? <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 font-semibold">... Uploading ...</p> : (
                <>
                  {file ? <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 font-semibold">{file.name}</p> : (
                    <>
                      <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">DWG, PDF, CDR, AI, PNG (MAX. 200MB)</p>
                    </>
                  )}
                </>
              )}

            </div>
            <input id="dropzone-file" type="file" onChange={onChange} className="hidden" />
          </label>
        </div>
      </div>
      {/* <div className="hidden lg:block relative h-24 w-full px-20 py-4">
        <div className="w-full flex flex-row justify-end">
          <NextButton next={next} isNextActive={true} finish={true} />
        </div>
      </div> */}
    </div>
  );
}