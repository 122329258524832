import { RootState } from "@/utils/store";
import { toSize, treemapData } from "../../data/treemap";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import classNames from "classnames";
import { TreemapBuilder, TreemapInfo } from "./layoutTreemap"

type Children = string | JSX.Element | JSX.Element[]

const SummaryContainer = ({ title, children }: { title: string, children: Children }) => (
  <div className="relative w-full h-fit my-8">
    <div className="bg-white shadow-md rounded-md">
      <div className="px-10 pb-2 pt-6 border-b border-zinc-200">
        <h1 className="font-bold text-lg my-2">{title}</h1>
      </div>
      <div className="p-10">
        {children}
      </div>
    </div>
  </div>
)


const KeyValue = ({ keyName, value }: { keyName: string, value: string | undefined | null }) => {
  return (
    <div className="bg-zinc-100 p-4 rounded-md">
      <p><span className="font-bold">{keyName}</span></p>
      <p>{value ?? "-"}</p>
    </div>
  )
}

const TreemapStuff = ({ spaceConfigRaw, totalArea }: { spaceConfigRaw: any, totalArea: number }) => {
  const size = toSize(totalArea)
  const keys = Object.keys(spaceConfigRaw)

  const padding = spaceConfigRaw?.hidden?.find((d: any) => d.id === "circulation")?.area[size] ?? 0

  const finalArea = keys.filter(k => k !== "hidden").reduce((area: number, k: string) => {
    return area + (
      (k === "Open Workspaces")
        ? (spaceConfigRaw[k].reduce((acc: number, cur: any) => (acc + (cur.units * cur.apu[size] * (1 + padding)) + (cur.extra ? cur.extra : 0)), 0))
        : (spaceConfigRaw[k].reduce((acc: number, cur: any) => (acc + (cur.units * cur.apu[size]) + (cur.extra ? cur.extra : 0)), 0))
    )
  }, 0)
  return (
    <div className="flex flex-col xl:flex-row relative justify-start gap-4 overflow-hidden max-w-screen-2xl w-full">
      <div className="flex flex-col basis-2/3">
        <div className="relative aspect-2/1">
          <TreemapBuilder data={spaceConfigRaw} totalArea={totalArea} keys={keys} />
        </div>
        {finalArea > totalArea && <div className={classNames('absolute w-full h-full border top-0 left-0 bg-red-500/70 rounded-sm')}></div>}
      </div>
      <TreemapInfo data={spaceConfigRaw} totalArea={totalArea} keys={keys} finalArea={finalArea} />
    </div>
  )
}

const PercentageBar = ({ percentage, name, desc }: { percentage: string | number, name: string, desc: string }) => {
  return (
    <div className="flex flex-col py-2 sm:py-2">
      <h3 className="font-bold text-xl">{name}</h3>
      <div className="relative w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700 sm:my-2 md:my-3 lg:my-5">
        <div
          className={classNames(
            "bg-blue-600 h-full rounded-full flex justify-end"
          )}
          style={{ "width": `${percentage}%` }}>
          <span className="text-xs text-white pr-1 font-semibold">{percentage}%</span>
        </div>
      </div>
      <p className="text-xs md:text-sm">{desc}</p>
    </div>
  )
}

export default function Summary() {

  const loaderData: any = useLoaderData();
  console.log(loaderData)

  const projectId = loaderData?.data?.project?.id

  const user = loaderData?.data?.project?.user
  const website = loaderData?.data?.project?.website
  const kind = loaderData?.data?.project?.kind
  const companyName = loaderData?.data?.project?.companyName
  const location = loaderData?.data?.project?.location

  const totalArea = loaderData?.data?.project?.carpetArea
  const spaceConfig = loaderData?.data?.project?.spaceConfig
  const spaceConfigRaw = loaderData?.data?.project?.spaceConfigRaw
  const layoutSelection = loaderData?.data?.project?.layoutSelection
  const furnitureSelection = loaderData?.data?.project?.furnitureSelection
  const basePlan = loaderData?.data?.project?.basePlan
  const size = toSize(totalArea)

  const submitted = loaderData?.data?.project?.submitted

  console.log(projectId, totalArea, size)
  console.log(spaceConfig)
  console.log(spaceConfigRaw)
  console.log(furnitureSelection)
  console.log(basePlan)
  console.log(layoutSelection)


  /* FURNITURE SELECTION */
  const basic = furnitureSelection.filter((x: any) => (x && x.title === "Classic"))
  const prime = furnitureSelection.filter((x: any) => (x && x.title === "Prime"))
  const furnitureData = {
    "Classic": {
      "total": basic.length,
      "ratio": Math.round(100 * basic.length / furnitureSelection.length),
      "images": basic.map((b: any) => b.image),
      "thumbnails": basic.map((b: any) => b.thumbnail)
    },
    "Prime": {
      "total": prime.length,
      "ratio": Math.round(100 * prime.length / furnitureSelection.length),
      "images": prime.map((b: any) => b.image),
      "thumbnails": prime.map((b: any) => b.thumbnail)
    }
  }

  return (
    <div className="relative w-full h-screen flex flex-col">
      <div className="w-full px-10 bg-white shadow-sm z-20">
        <div className="px-10 py-4">
          <h1 className="text-xl font-bold">Project Summary</h1>
          {submitted ?
            <p className="">Submitted</p>
            :
            <p className="text-red">Unsubmitted</p>
          }
        </div>
      </div>
      <div className="w-full grow overflow-scroll z-10">
        <div className="relative w-full flex flex-col gap-8 bg-zinc-100 px-10">
          <SummaryContainer title="Project Information">
            <div className="w-full flex flex-col xl:flex-row gap-8 mx-auto">
              <div className="w-full grid grid-cols-3 gap-2">
                <KeyValue keyName="Name" value={user.name} />
                <KeyValue keyName="Email" value={user.email} />
                <KeyValue keyName="Phone" value={user.phone} />
                <KeyValue keyName="Company" value={companyName} />
                <KeyValue keyName="Website" value={website} />
                <KeyValue keyName="Location" value={location} />
                <KeyValue keyName="Kind" value={kind} />
              </div>
            </div>
          </SummaryContainer>
          <SummaryContainer title="Preferences">
            <div className="w-full flex flex-col xl:flex-row gap-8 mx-auto">
              <div className="basis-1/2 mx-auto flex flex-col gap-6 rounded-md py-6 justify-between">
                <img src={layoutSelection.image} alt={layoutSelection.name} className="aspect-4/3 rounded-md object-cover" />
                <div>
                  <p className="text-md text-center">Layout</p>
                  <p className="text-xl font-bold text-center">{layoutSelection.name}</p>
                </div>
              </div>
              <div className="basis-1/2 mx-auto flex flex-col gap-6">
                <div className="grid grid-cols-4 gap-2">
                  {furnitureSelection.map((f: any) => (
                    <>
                      {f &&
                        <div key={f.id} className="relative aspect-4/3 shadow-md rounded-md">
                          <img src={f.image} alt={f.title} className="aspect-4/3 rounded-md" />
                          <p className="absolute bottom-0 px-1 bg-white/70 rounded-tr-md">{f.title}</p>
                        </div>
                      }
                    </>
                  ))}
                </div>
                {basic.length > 0 && <PercentageBar name="Daftar Classic" percentage={furnitureData["Classic"]["ratio"]} desc="Bold designs, fine materials made affordable for modern needs." />}
                {prime.length > 0 && <PercentageBar name="Daftar Prime" percentage={furnitureData["Prime"]["ratio"]} desc="Premium aesthetics with practical function, for a unique office personality." />}
              </div>
            </div>
          </SummaryContainer>
          {spaceConfigRaw &&
            <SummaryContainer title="Space Configuration">
              <TreemapStuff totalArea={totalArea} spaceConfigRaw={spaceConfigRaw} />
            </SummaryContainer>
          }
          {/* <SummaryContainer title="Layout">
        Layout Here
      </SummaryContainer> */}
          {/* <h3 className="font-bold text-xl">Thank you for choosing Daftar Decor</h3>
      <p className="text-lg">We accept the challenge, will get in touch shortly.</p>
      <a href="https://daftardecor.com" className="text-blue-500">Go back to Website</a> */}
        </div >
      </div>
      <div className="w-full z-20 flex flex-row justify-center items-center shadow-sm bg-zinc-50">
        <p className="px-10 py-2">Back to <a href="https://www.daftardecor.com" className="font-semibold text-indigo-600">daftardecor.com</a></p>
      </div>
    </div>
  );
}